import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Button,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import Mybutton from "../../../Component/Mybutton/Mybutton";
import "./InkConsumption.css";
import axios from "axios";
import { BASE_URL } from "../../../Utils/constants";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
import "./InkConsumption.css";
import CustomLoader from "../../../Component/Loader/CustomLoader";
import { ImSpinner5 } from "react-icons/im";
import "bootstrap-icons/font/bootstrap-icons.css";

function InkConsumption() {
  // State management for form fields
  const [formData, setFormData] = useState({
    poNo: "",
    quotationNo: "",
    createdDate: "",
    tax_invoice_date: "",
    poDate: "",
    item: "",
    rate: "",
    tax_invoiceNo: "",
    taxInvoiceQty: "",
    unit: "",
    totalquantity: "",
    // quantity_number: "",
    // location: "",
    // tax_invoice_quantity: "",
  });

  const unitOptions = [
    { value: "gm", label: "gm" },
    { value: "kg", label: "kg" },
    { value: "nos", label: "nos" },
    { value: "mm", label: "mm" },
    { value: "m", label: "m" },
  ];

  // State for dropdown data
  // const [inkId, setInkId] = useState([]);
  const [inkData, setInkData] = useState([]);
  const [selectedInkId, setSelectedInkId] = useState("");
  const [selectedInk_Value, setSelectedInk_Value] = useState("");
  const [consumptionPerSku, setConsumptionPerSku] = useState("");
  const [taxinvoiceqty, settaxinvoiceqty] = useState("");
  const [totalquantity, settotalquantity] = useState("");

  const [consumableData, setConsumableData] = useState([]);
  const [preImportData, setPreImportData] = useState(null);
  const [preImportLoading, setPreImportLoading] = useState(false);
  const [confirmImportLoading, setConfirmImportLoading] = useState(false);
  const [errorRows, setErrorRows] = useState(0);
  const [validRows, setValidRows] = useState(0);
  // console.log("consumableData", consumableData);
  const [loader, serLoader] = useState(true);

  const [search, setsearch] = useState("");
  const [vendorId, setvendorId] = useState([]);
  const [selectedvendorId, setselectedvendorId] = useState("");
  const [selectedSearchvendorId, setSearchselectedvendorId] = useState(null);

  // console.log("vendorId", vendorId);

  const [loadingId, setLoadingId] = useState(true);

  // State for Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);

  // urls
  const getConsumableData_url = BASE_URL + "api/getinkconsumble";
  const getProductionData_url = BASE_URL + "api/inkconsumble/"; //post method
  const importProductionDataUrl = BASE_URL + "api/import-ink-consumable/"; //post method
  const confirmImportProductionDataUrl =
    BASE_URL + "api/confirm-import-ink-consumable/"; //post method
  const exportData_url = BASE_URL + "api/GETInkconsumableAPIView/";
  // const vendorId_url = BASE_URL + "/api/getinkconsumble/";
  const getvendorDetails_url = BASE_URL + "api/Getplantdata/";

  const [filterDates, setFilterDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [importSwitch, setImportSwitch] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showImportConfirmationDialog, setShowImportConfirmationDialog] =
    useState(false);

  const handleImportSwitch = (e) => {
    setImportSwitch(e.target.checked);
  };

  const handleFileSelection = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Fetch data for dropdown when component mounts
  const fetchDropdownData = async () => {
    setLoadingId(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .get(getConsumableData_url, {
        headers: header,
      })
      .then((res) => {
        if (res.data.status) {
          // setInkId(res.data.data);
          // console.log("data", res.data.data);
          const filteredData = res.data?.data.filter(
            (item) => item.location === selectedvendorId
          );

          // console.log("filteredData", filteredData);
          setInkData(filteredData);
        }
        setLoadingId(false);
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err.message);
        setLoadingId(false);
      });
  };

  // Handler for form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUnitChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      unit: value,
    }));
  };

  // const handleRateChange = (e) => {
  //   const { value } = e.target;
  //   const rate = parseFloat(value);

  //   const basicRate = rate * parseFloat(taxInvoiceQty || 0);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     rate: value,
  //     basicRate: basicRate.toString(),
  //   }));
  // };
  const handleRateChange = (e) => {
    const { value } = e.target;
    const rate = parseFloat(value); // Convert the rate value to a number
    const taxInvoiceQty = parseFloat(taxinvoiceqty || 0); // Ensure taxInvoiceQty is a valid number

    // Calculate basicRate as rate * taxInvoiceQty
    const basicRate = rate * taxInvoiceQty;

    // Update the form data state
    setFormData((prevData) => ({
      ...prevData,
      rate: value,
      basicRate: basicRate.toFixed(2), // Optionally format basicRate to 2 decimal places
    }));
  };

  // to calculate basic rate with qty change
  const handleQtyChange = (e) => {
    const { value } = e.target;
    const taxInvoiceQty = parseFloat(value); // Convert to number
    const rate = parseFloat(formData.rate || 0); // Ensure rate is a number

    // Calculate basicRate as rate * taxInvoiceQty
    const basicRate = rate * taxInvoiceQty;

    // Update form data with taxInvoiceQty and basicRate
    setFormData((prevData) => ({
      ...prevData,
      taxInvoiceQty: value,
      basicRate: basicRate.toFixed(2), // Format basicRate to 2 decimal places
    }));
  };

  const handleautofillData = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "poNo") {
      const poEntry = consumableData.find((item) => item.po_number === value);
      // console.log("poEntry", poEntry);

      if (poEntry) {
        setFormData((prevData) => ({
          ...prevData,
          poDate: poEntry.po_date,
          quotationNo: poEntry.quantion_number,
          createdDate: poEntry.quantion_date,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          poDate: "",
          quotationNo: "",
          createdDate: "",
        }));
      }
    }
  };

  const handleSelect = (item) => {
    // console.log(item);
    setSelectedInkId(item.ink_descripation);
    setSelectedInk_Value(item.InkID);
    setConsumptionPerSku(item.consumablepersku || "");
    settaxinvoiceqty(item.tax_invoice_quantity || "");
    settotalquantity(item.totalquantity || "");
  };

  //post data  Handler for form submission
  const handleSubmit = async () => {
    if (
      !selectedInk_Value ||
      !formData.poNo ||
      !formData.poDate ||
      !formData.quotationNo ||
      !formData.createdDate ||
      !selectedInkId ||
      !formData.rate ||
      !formData.tax_invoice_date ||
      // !formData.quantity_number ||
      !formData.unit ||
      // !formData.location ||
      !formData.tax_invoiceNo ||
      !formData.taxInvoiceQty ||
      !formData.totalquantity
    ) {
      toast.error("All fields are required");
      return;
    }

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const data = new FormData();
    data.append("po_number", formData.poNo);
    data.append("quantion_number", formData.quotationNo);
    data.append("po_date", formData.poDate);
    data.append("quantion_date", formData.createdDate);
    data.append("taxinvoice_date", formData.tax_invoice_date);
    data.append("ink_items", selectedInk_Value);
    data.append("rate", formData.rate);
    // data.append("quantity_number", formData.quantity_number);
    data.append("basic_rate", formData.basicRate);
    data.append("total_gst", (formData.basicRate * 0.18).toFixed(3));
    data.append(
      "total_ammount",
      parseInt(formData.basicRate) + parseInt(formData.basicRate * 0.18)
    );
    // data.append("location", formData.location);
    data.append("taxinvoiceNo", formData.tax_invoiceNo);
    data.append("taxinvoice_quantity", parseFloat(formData.taxInvoiceQty));
    data.append("unit", formData.unit);
    // data.append("location", selectedvendorId);
    data.append("plantindata", selectedvendorId);
    data.append("total_quantity", formData.totalquantity);

    await axios

      .post(getProductionData_url, data, {
        headers: header,
      })
      .then((res) => {
        if (res.data.status) {
          // setConsumableData(res.data.data);

          toast.success(res.data.message);
          setFormData({
            poNo: "",
            poDate: "",
            quotationNo: "",
            createdDate: "",
            tax_invoice_date: "",
            item: "",
            rate: "",
            quantity_number: "",
            unit: "",
            location: "",
            tax_invoiceNo: "",
            taxInvoiceQty: "",
            totalquantity: "",
          });
          setSelectedInk_Value("");
          setSelectedInkId("");
          setConsumptionPerSku("");
          fetchConsumableData();
          setselectedvendorId("");
          settaxinvoiceqty("");
          settotalquantity("");
        }
      })
      .catch((err) => {
        toast.error(err.message);
        // console.log(err);
      });
  };

  const handleImport = async () => {
    setPreImportLoading(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const data = new FormData();

    data.append("file", selectedFile);
    data.append("vendor_id", selectedvendorId);

    await axios

      .post(importProductionDataUrl, data, {
        headers: header,
      })
      .then((res) => {
        if (res != null) {
          let error = 0;
          let valid = 0;
          res.data.pre_import_data.forEach((item) => {
            if (
              item.quantion_date &&
              item.vendor_location &&
              item.created_date &&
              item.ink_item &&
              item.po_number &&
              item.po_date &&
              item.total_quantity &&
              ["gm", "kg", "nos", "mm", "m"].includes(item.unit) &&
              (item.quantion_number !== 0 ||
                item.quantion_number !== null ||
                item.quantion_number !== undefined) &&
              (item.rate !== null || item.rate !== undefined) &&
              (item.basic_rate !== null || item.basic_rate !== undefined) &&
              (item.total_gst !== null || item.total_gst !== undefined) &&
              (item.total_ammount !== null || item.total_gst !== undefined)
            ) {
              valid++;
            } else {
              error++;
            }
          });

          setErrorRows(error);
          setValidRows(valid);
          setPreImportData(res.data.pre_import_data);
          setPreImportLoading(false);
          setShowImportConfirmationDialog(true);
          // setConsumableData(res.data.data);
        }
      })
      .catch((err) => {
        // console.log(err);
        console.log("", err);

        toast.error(err.response.data.message);
        setPreImportLoading(false);
      });
  };

  const confirmImport = async () => {
    setConfirmImportLoading(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    // const data = new FormData();
    // data.append("file", selectedFile);
    // data.append("vendor_id", selectedvendorId);

    // console.log(
    //   preImportData.filter((item) => {
    //     return (
    //       item.quantion_date &&
    //       item.created_date &&
    //       item.ink_items &&
    //       item.po_number &&
    //       item.po_date &&
    //       item.total_quantity &&
    //       item.unit &&
    //       (item.rate !== null || item.rate !== undefined) &&
    //       (item.basic_rate !== null || item.basic_rate !== undefined) &&
    //       (item.total_gst !== null || item.total_gst !== undefined) &&
    //       (item.total_ammount !== null || item.total_gst !== undefined)
    //     );
    //   })
    // );

    await axios

      .post(
        confirmImportProductionDataUrl,
        {
          data: preImportData.filter((item) => {
            return (
              item.quantion_date &&
              item.created_date &&
              item.ink_item &&
              item.po_number &&
              item.po_date &&
              item.total_quantity &&
              item.unit &&
              (item.rate !== null || item.rate !== undefined) &&
              (item.basic_rate !== null || item.basic_rate !== undefined) &&
              (item.total_gst !== null || item.total_gst !== undefined) &&
              (item.total_ammount !== null || item.total_ammount !== undefined)
            );
          }),
        },
        {
          headers: header,
        }
      )
      .then((res) => {
        if (res != null) {
          fetchConsumableData();
          toast.success("Data imported successfully.");
          setShowImportConfirmationDialog(false);
          setConfirmImportLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setConfirmImportLoading(false);
      });
  };

  const fetchConsumableData = async (startDate, endDate, vendorId) => {
    serLoader(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getProductionData_url, {
        headers: header,
        params: {
          from_date: startDate || "",
          to_date: endDate || "",
          vendor_id: vendorId,
          page: currentPage,
          page_size: pageSize,
        },
      })
      .then((res) => {
        // console.log(res.data.data);

        if (res.data.status) {
          const { data, pagination } = res.data;
          setConsumableData(data);

          if (pagination && pagination.length > 0) {
            const totalItems = pagination[0].total;
            const calculatedTotalPages = Math.ceil(totalItems / pageSize);
            setTotalPages(calculatedTotalPages);
          }
        }
        serLoader(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error("Unathorized user", "error");
        }
        serLoader(false);
      });
  };

  const getVendorDetails = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .get(getvendorDetails_url, { headers: header })
      .then((res) => {
        // console.log("vendor", res.data.data);

        if (res.data.status) {
          setvendorId(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        // console.log(err);
      });
  };
  useEffect(() => {
    getVendorDetails();
  }, []);

  const handleVendorSelect = (item) => {
    setselectedvendorId(item?.location);
    // setSelectedVendorValue(item.plantID);
  };

  const handleVendorSelect2 = (item) => {
    // console.log(item);

    if (item?.location === "All locations") {
      setSearchselectedvendorId(null);
    } else {
      setSearchselectedvendorId(item?.location);
    }
    // setSelectedVendorValue(item.plantID);
  };

  useEffect(() => {
    if (selectedvendorId) {
      fetchDropdownData();
    }
  }, [selectedvendorId]);

  useEffect(() => {
    fetchConsumableData();
  }, [currentPage]);

  const filteredConsumableData = consumableData;
  //   .filter((item) => {
  //   // newly added data
  //   const itemDate = new Date(item.quantion_date);
  //   const startDate = new Date(filterDates.startDate);
  //   const endDate = new Date(filterDates.endDate);

  //   return (
  //     (!filterDates.startDate || itemDate >= startDate) &&
  //     (!filterDates.endDate || itemDate <= endDate)
  //   );
  // });
  // .sort((a, b) => new Date(b.quantion_date) - new Date(a.quantion_date));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = () => {
    if (
      filterDates.startDate &&
      filterDates.endDate &&
      selectedSearchvendorId
    ) {
      fetchConsumableData(
        filterDates.startDate,
        filterDates.endDate,
        selectedSearchvendorId
      );
    } else if (filterDates.startDate && filterDates.endDate) {
      fetchConsumableData(filterDates.startDate, filterDates.endDate);
    } else if (selectedSearchvendorId) {
      fetchConsumableData(null, null, selectedSearchvendorId);
    } else {
      fetchConsumableData();
    }
  };

  const totals = filteredConsumableData.reduce(
    (acc, item) => {
      acc.quantityNumber += item.quantion_number || 0;
      acc.poNumber += parseFloat(item.po_number) || 0;
      acc.total_quantity += parseFloat(item.totalQty) || 0;
      acc.basicRate += Number(item.basic_rate) || 0;
      acc.totalGst += Number(item.total_gst) || 0;
      acc.totalAmmount += Number(item.total_ammount) || 0;
      acc.rate += item.rate || 0;

      if (item.ink_items?.consumablepersku) {
        acc.consumablePerSKU += parseFloat(item.ink_items.consumablepersku);
      }
      return acc;
    },

    {
      quantityNumber: 0,
      poNumber: 0,
      consumablePerSKU: 0,
      rate: 0,
      basicRate: 0,
      totalGst: 0,
      totalAmmount: 0,
      totalQty: 0,
    }
  );

  const downloadExcel = async (startDate, endDate, vendorId) => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    try {
      const response = await axios.get(exportData_url, {
        headers: header,
        params: {
          from_date: startDate || "",
          to_date: endDate || "",
          vendor_id: vendorId,
        },
      });

      if (response.data.status) {
        const fetchedData = response.data.data;

        // Flatten the data structure
        const flattenedData = fetchedData.map((item) => ({
          inkconsumId: item.inkconsumId,
          InkID: item.ink_items.InkID,
          ink_item: item.ink_items.ink_item,
          ink_descripation: item.ink_items.ink_descripation,
          consumablepersku: item.ink_items.consumablepersku
            ? parseFloat(item.ink_items.consumablepersku)
            : 0,
          vendor_location: item.ink_items.location,
          po_number: item.po_number,
          quantion_number: item.quantion_number,
          quantion_date: item.quantion_date,
          created_date: item.created_date,
          total_quantity: parseFloat(item.total_quantity) || 0,
          rate: parseFloat(item.rate) || 0,
          basic_rate: parseFloat(item.basic_rate) || 0,
          total_gst: parseFloat(item.total_gst) || 0,
          total_ammount: parseFloat(item.total_ammount) || 0,
        }));

        // Calculate the total of consumablepersku
        const totalConsumablepersku = flattenedData.reduce(
          (total, item) => total + item.consumablepersku,
          0
        );
        const totalRate = flattenedData.reduce(
          (total, item) => total + item.rate,
          0
        );
        const totalBasicRate = flattenedData.reduce(
          (total, item) => total + item.basic_rate,
          0
        );
        const totalGst = flattenedData.reduce(
          (total, item) => total + item.total_gst,
          0
        );
        const totalAmount = flattenedData.reduce(
          (total, item) => total + item.total_ammount,
          0
        );
        const totalQty = flattenedData.reduce(
          (total, item) => total + item.total_quantity,
          0
        );

        // Append the total row
        flattenedData.push({
          inkconsumId: "Total",
          InkID: "",
          ink_item: "",
          ink_descripation: "",
          consumablepersku: totalConsumablepersku,
          po_number: "",
          quantion_number: "",
          quantion_date: "",
          created_date: "",
          // totalquantity: "",
          total_quantity: totalQty,
          rate: totalRate,
          basic_rate: totalBasicRate,
          total_gst: totalGst,
          total_ammount: totalAmount,
        });

        // console.log("export", flattenedData);
        const worksheet = XLSX.utils.json_to_sheet(flattenedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Ink Consumables");
        XLSX.writeFile(workbook, "InkConsumables.xlsx");
      }
    } catch (error) {
      console.error("Error fetching data for export:", error);
      if (error.response?.status === 401) {
        toast.error("Unauthorized user", "error");
      }
    }
  };

  const filterData = filteredConsumableData.filter(
    (itm) =>
      itm.inkconsumId.toLowerCase().includes(search.toLowerCase()) ||
      // itm.created_date.toLowerCase().includes(search.toLowerCase())
      itm.quantion_date?.toLowerCase().includes(search.toLowerCase()) ||
      itm.ink_items.ink_item.toLowerCase().includes(search.toLowerCase()) ||
      itm.ink_items?.ink_descripation
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      itm.ink_items?.consumablepersku
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      itm.po_number?.toLowerCase().includes(search.toLowerCase()) ||
      itm.quantity_number?.toLowerCase().includes(search.toLowerCase()) ||
      itm.total_quantity?.toLowerCase().includes(search.toLowerCase()) ||
      itm.unit?.toLowerCase().includes(search.toLowerCase()) ||
      itm.location?.toLowerCase().includes(search.toLowerCase()) ||
      itm.taxinvoiceNo?.toLowerCase().includes(search.toLowerCase()) ||
      itm.taxinvoice_quantity?.toLowerCase().includes(search.toLowerCase()) ||
      itm.rate?.toString().includes(search) ||
      itm.basic_rate?.toString().includes(search) ||
      itm.total_gst?.toString().includes(search) ||
      itm.total_ammount?.toString().includes(search) ||
      itm.ink_items?.location?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Toaster />
      <Modal
        show={showImportConfirmationDialog}
        animation={false}
        style={{ maxHeight: "900px" }}
        size="xl"
      >
        <Modal.Body>
          <div className="p-2 table-container">
            <div className="card card_main ">
              <div className="card-body">
                <div className="table-responsive" style={{ height: "65vh" }}>
                  {preImportData != null && preImportData.length > 0 ? (
                    <>
                      <table className="table table-bordered">
                        <thead className="sticky-top bg-light">
                          <tr>
                            <th className="table_head text-dark" rowSpan={2}>
                              Sr. No.
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Quotation Date
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Vendor Location
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Created Date
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Quotation No.
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Ink ID
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              PO Number
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              PO Date
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Total Qty
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Unit
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Tax Invoice Number
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Tax Invoice Quantity
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Tax Invoice Date
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Rate
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Basic Rate
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Total GST(18%)
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Total Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {preImportData.map((item, index) => {
                            let valid =
                              item.quantion_date &&
                              item.vendor_location &&
                              item.created_date &&
                              item.ink_item &&
                              item.po_number &&
                              item.po_date &&
                              item.total_quantity &&
                              ["gm", "kg", "nos", "mm", "m"].includes(
                                item.unit
                              ) &&
                              item.quantion_number !== 0 &&
                              item.quantion_number !== null &&
                              item.quantion_number !== undefined &&
                              item.rate !== null &&
                              item.rate !== undefined &&
                              item.basic_rate !== null &&
                              item.basic_rate !== undefined &&
                              item.total_gst !== null &&
                              item.total_gst !== undefined &&
                              item.total_ammount !== null &&
                              item.total_gst !== undefined;
                            console.log("valid", valid, index);

                            return (
                              <tr
                                className={"table_row"}
                                style={{
                                  backgroundColor: !valid
                                    ? "#ff000030"
                                    : "#00800021",
                                }}
                                key={index}
                              >
                                {/* <td className="table_data">
                                  {item.inkconsumId}
                                </td> */}
                                <td
                                  style={{
                                    color: "grey",
                                  }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    background: !item.quantion_date
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.quantion_date}
                                </td>

                                <td
                                  style={{
                                    background: !item.vendor_location
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.vendor_location || "Unknown"}
                                </td>
                                <td
                                  style={{
                                    background: !item.created_date
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.created_date || 0}
                                </td>
                                <td
                                  style={{
                                    background: !parseInt(item.quantion_number)
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.quantion_number}
                                </td>
                                <td
                                  style={{
                                    background: !item.ink_item
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.ink_item || "Unknown"}
                                </td>
                                <td
                                  style={{
                                    background: !parseInt(item.po_number)
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.po_number}
                                </td>
                                {/* <td>{item.ink_items?.consumablepersku || 0}</td> */}
                                <td
                                  style={{
                                    background: !item.po_date
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.po_date || 0}
                                </td>
                                <td
                                  style={{
                                    background: !item.po_date
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.total_quantity || 0}
                                </td>
                                <td
                                  style={{
                                    background: ![
                                      "gm",
                                      "kg",
                                      "nos",
                                      "mm",
                                      "m",
                                    ].includes(item.unit)
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.unit}
                                </td>
                                <td
                                  style={{
                                    background: "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.taxinvoiceNo}
                                </td>
                                {/* <td>{item.quantity_number}</td> */}
                                <td
                                  style={{
                                    background: "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.taxinvoice_quantity}
                                </td>
                                {/* <td>{item.location}</td> */}
                                <td
                                  style={{
                                    background: "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.taxinvoice_date}
                                </td>

                                <td
                                  style={{
                                    background: !(
                                      item.rate !== null ||
                                      item.rate !== undefined
                                    )
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.rate}
                                </td>
                                <td
                                  style={{
                                    background: !(
                                      item.basic_rate !== null ||
                                      item.basic_rate !== undefined
                                    )
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.basic_rate}
                                </td>
                                <td
                                  style={{
                                    background: !(
                                      item.total_gst !== null ||
                                      item.total_gst !== undefined
                                    )
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.total_gst}
                                </td>
                                <td
                                  style={{
                                    background: !(
                                      item.total_ammount !== null ||
                                      item.total_gst !== undefined
                                    )
                                      ? "#ff000040"
                                      : "none",
                                    color: !valid ? "red" : "green",
                                  }}
                                >
                                  {item.total_ammount}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center ">
                      <p className="text-danger fw-bold">No Data Found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-danger">Invalid rows: {errorRows}</p> •
          <p className="text-success">Valid rows: {validRows}</p>
          <Button
            variant="danger"
            onClick={() => setShowImportConfirmationDialog(false)}
          >
            Cancel
          </Button>
          {!confirmImportLoading ? (
            <Button
              variant="primary"
              onClick={confirmImport}
              disabled={validRows === 0}
            >
              Confirm import {validRows} rows
            </Button>
          ) : (
            <Button variant="primary" disabled>
              <ImSpinner5
                icon="spinner"
                className="spinner"
                style={{ fontSize: "20px" }}
              ></ImSpinner5>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {loader ? (
        <CustomLoader />
      ) : (
        <>
          <div className="row ">
            <div className="col-8 col-lg-8 col-md-8 px-4 my-2">
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Import Excel"
                checked={importSwitch}
                onChange={handleImportSwitch}
              />
            </div>
            {importSwitch && (
              <div
                className="col-4 col-lg-4 col-md-4 px-4 my-2 "
                style={{ textAlign: "end" }}
              >
                <a href="/Inkconsumable-Format.xlsx" download>
                  <button
                    type="button"
                    class="btn btn-light"
                    style={{
                      border: "1px solid rgb(123, 123, 255)",
                      color: "rgb(123, 123, 255)",
                      backgroundColor: "white",
                    }}
                  >
                    <i
                      class="bi bi-download"
                      style={{
                        fontSize: "15px",
                        color: "rgb(123, 123, 255)",
                        marginRight: "5px",
                      }}
                    ></i>
                    Download Format
                  </button>
                </a>
              </div>
            )}
          </div>
          {importSwitch && (
            <div
              className="row p-2 m-3 main_class"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 show">
                <InputGroup>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Select Vendor Id"
                  >
                    {vendorId.length
                      ? vendorId.map((item) => {
                          return (
                            <Dropdown.Item
                              key={item?.location}
                              onClick={() => handleVendorSelect(item)}
                            >
                              {item?.location}
                            </Dropdown.Item>
                          );
                        })
                      : ""}
                  </DropdownButton>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    readOnly
                    aria-label="Text input with dropdown button"
                    value={
                      selectedvendorId ? selectedvendorId : "Select vendor"
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 show">
                <InputGroup>
                  {/* <Form.Label>Upload a file</Form.Label> */}
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    type="file"
                    onChange={handleFileSelection}
                  />
                </InputGroup>
              </div>
              <div className="text-center p-2 mt-2">
                {!preImportLoading ? (
                  <Mybutton
                    name={"Import Data"}
                    backgroundColor={"#7b7bff"}
                    color={"#fff"}
                    handleClick={handleImport}
                  />
                ) : (
                  <Button disabled>
                    <ImSpinner5
                      icon="spinner"
                      className="spinner"
                      style={{ fontSize: "20px" }}
                    ></ImSpinner5>
                  </Button>
                )}
              </div>
            </div>
          )}
          {!importSwitch && (
            <div
              className="row p-2 m-3 main_class"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 show">
                <InputGroup>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Select Vendor Id"
                    // style={{ minWidth: "50px" }}
                  >
                    {vendorId.length
                      ? vendorId.map((item) => {
                          return (
                            <Dropdown.Item
                              key={item?.location}
                              onClick={() => handleVendorSelect(item)}
                            >
                              {item?.location}
                            </Dropdown.Item>
                          );
                        })
                      : ""}
                  </DropdownButton>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    readOnly
                    aria-label="Text input with dropdown button"
                    value={
                      selectedvendorId ? selectedvendorId : "Select vendor"
                    }
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 hide">
                <InputGroup>
                  <DropdownButton
                    style={{ border: "1px solid grey " }}
                    disabled={loadingId}
                    variant="outline-secondary"
                    title="Select Ink Id"
                  >
                    {inkData.length
                      ? inkData.map((item) => {
                          return (
                            <Dropdown.Item
                              key={item?.ink_item}
                              onClick={() => handleSelect(item)}
                            >
                              {item?.ink_item}
                            </Dropdown.Item>
                          );
                        })
                      : ""}
                  </DropdownButton>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    readOnly
                    aria-label="Text input with dropdown button"
                    value={selectedInkId ? selectedInkId : "Select Ink"}
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 hide">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    PO No.
                  </span>
                  <input
                    value={formData.poNo}
                    // onChange={handleInputChange}
                    onChange={handleautofillData}
                    name="poNo"
                    type="text"
                    placeholder="po_number"
                    className="form-control"
                    aria-label="poNo"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%", border: "1px solid grey" }}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 hide">
                <InputGroup>
                  <InputGroup.Text
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    PO Date
                  </InputGroup.Text>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    type="date"
                    name="poDate"
                    value={formData.poDate}
                    onChange={handleInputChange}
                    placeholder="YYYY-MM-DD"
                    aria-label="Po Date"
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 hide">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Quotation No.
                  </span>
                  <input
                    style={{ border: "1px solid grey" }}
                    value={formData.quotationNo}
                    onChange={handleInputChange}
                    name="quotationNo"
                    type="number"
                    placeholder="quotation_number"
                    className="form-control"
                    aria-label="quotationNo"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 hide">
                <InputGroup>
                  <InputGroup.Text
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Created Quotation Date
                  </InputGroup.Text>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    type="date"
                    name="createdDate"
                    value={formData.createdDate}
                    onChange={handleInputChange}
                    placeholder="YYYY-MM-DD"
                    aria-label="Created Quotation Date"
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2 hide">
                <InputGroup>
                  <InputGroup.Text
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Tax Invoice Date
                  </InputGroup.Text>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    type="date"
                    name="tax_invoice_date"
                    value={formData.tax_invoice_date}
                    onChange={handleInputChange}
                    placeholder="YYYY-MM-DD"
                    // aria-label="Tax Invoice Date"
                  />
                </InputGroup>
              </div>
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <InputGroup>
                <InputGroup.Text style={{ minWidth: "35%" }}>
                  Received Quantity
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  aria-label="With textarea"
                  name="consumptionPerSku"
                  placeholder="received quantity"
                  value={consumptionPerSku}
                  onChange={(e) => setConsumptionPerSku(e.target.value)}
                  style={{ height: "30px", resize: "none" }}
                  disabled
                />
              </InputGroup>
            </div> */}
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Rate
                  </span>
                  <input
                    style={{ border: "1px solid grey" }}
                    value={formData.rate}
                    onChange={handleRateChange}
                    name="rate"
                    type="number"
                    placeholder="rate"
                    className="form-control"
                    aria-label="rate"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <div className="input-group">
                <span
                  className="input-group-text"
                  id="basic-addon1"
                  style={{ minWidth: "35%" }}
                >
                  Quantity No.
                </span>
                <input
                  value={formData.quantity_number}
                  onChange={handleInputChange}
                  name="quantity_number"
                  type="number"
                  placeholder="quantity_number"
                  className="form-control"
                  aria-label="quantity_number"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div> */}
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Unit
                  </span>
                  <Form.Select
                    style={{ border: "1px solid grey" }}
                    aria-label="Default select example"
                    value={formData.unit}
                    onChange={handleUnitChange}
                  >
                    <option>Select Unit</option>
                    {unitOptions.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <div className="input-group">
                <span
                  className="input-group-text"
                  id="basic-addon1"
                  style={{ minWidth: "35%" }}
                >
                  Location
                </span>
                <input
                  value={formData.location}
                  onChange={handleInputChange}
                  name="location"
                  type="text"
                  placeholder="location"
                  className="form-control"
                  aria-label="location"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div> */}
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Tax Invoice No.
                  </span>
                  <input
                    style={{ border: "1px solid grey" }}
                    value={formData.tax_invoiceNo}
                    onChange={handleInputChange}
                    name="tax_invoiceNo"
                    type="number"
                    placeholder="tax_invoice_number"
                    className="form-control"
                    aria-label="taxinvoiceNo"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Tax Invoice Qty
                  </span>
                  <input
                    style={{ border: "1px solid grey" }}
                    value={formData.taxInvoiceQty}
                    // onChange={handleInputChange}
                    onChange={handleQtyChange}
                    name="tax_invoice_quantity"
                    type="number"
                    placeholder="tax_invoice_quantity"
                    className="form-control"
                    aria-label="taxinvoice_quantity"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Total Qty
                  </span>
                  <input
                    style={{ border: "1px solid grey" }}
                    value={formData.totalquantity}
                    onChange={handleInputChange}
                    // onChange={handleQtyChange}
                    name="totalquantity"
                    type="number"
                    placeholder="totalquantity"
                    className="form-control"
                    aria-label="totalquantity"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div className="text-center p-2 mt-2">
                <Mybutton
                  name={"Submit"}
                  backgroundColor={"#7b7bff"}
                  color={"#fff"}
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          )}

          <div
            className="p-2 m-3 main_class"
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              backgroundColor: "whitesmoke",
            }}
          >
            {/*export all  */}
            <div className="d-lg-flex align-items-center justify-content-center">
              <div className="col-lg-6 d-flex justify-content-lg-end">
                <h4 className="text-center px-2 justify-content-center align-items-center">
                  Consumable Data List
                </h4>
              </div>
              <div className="col-lg-6 d-lg-flex justify-content-lg-end justify-content-center gap-2">
                <div className="text-center me-2">
                  <Mybutton
                    name={"Export All"}
                    color={"#fff"}
                    backgroundColor={"#7b7bff"}
                    handleClick={() =>
                      downloadExcel(
                        filterDates.startDate,
                        filterDates.endDate,
                        selectedSearchvendorId
                      )
                    }
                  />
                </div>
              </div>
            </div>

            {/* search & reset button */}
            <div className="my-3 mx-2 p-2">
              <li className="my-2 ms-2 text-dark">Filter By Date</li>
              <div className="d-lg-flex justify-content-start align-items-end gap-2 flex-wrap">
                <div>
                  <h6
                    className="text-secondary mb-2"
                    style={{ fontSize: "13px" }}
                  >
                    From Date
                  </h6>
                  <input
                    style={{ border: "1px solid grey" }}
                    onChange={(e) => {
                      setFilterDates((prev) => ({
                        ...prev,
                        startDate: e.target.value,
                      }));
                    }}
                    value={filterDates.startDate}
                    type="date"
                    placeholder="YYYY-MM-DD"
                    className="form-control productiondate"
                  />
                </div>
                <div>
                  <h6
                    className="text-secondary mb-2 my-2"
                    style={{ fontSize: "13px" }}
                  >
                    To Date
                  </h6>
                  <input
                    style={{ border: "1px solid grey" }}
                    onChange={(e) => {
                      setFilterDates((prev) => ({
                        ...prev,
                        endDate: e.target.value,
                      }));
                    }}
                    value={filterDates.endDate}
                    type="date"
                    placeholder="YYYY-MM-DD"
                    className="form-control productiondate"
                  />
                </div>
                <div>
                  <DropdownButton
                    variant="outline-secondary"
                    title={selectedSearchvendorId || "All locations"}
                  >
                    {vendorId.length
                      ? [{ location: "All locations" }]
                          .concat(vendorId)
                          .map((item) => {
                            return (
                              <Dropdown.Item
                                key={item?.location}
                                onClick={() => handleVendorSelect2(item)}
                              >
                                {item?.location}
                              </Dropdown.Item>
                            );
                          })
                      : ""}
                  </DropdownButton>
                </div>

                <div className="d-flex text-center gap-1 justify-content-center mt-2">
                  <div className="">
                    <Mybutton
                      name={"Search"}
                      style={{ backgroundColor: "#7b7bff" }}
                      // newly added code
                      handleClick={handleSearch}
                    />
                  </div>

                  <div className="">
                    <Mybutton
                      backgroundColor={"#fb5e5e"}
                      name={"Reset Filter"}
                      handleClick={() => {
                        fetchConsumableData();
                        setSearchselectedvendorId(null);
                        setFilterDates({
                          startDate: "",
                          endDate: "",
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* search */}

          <div className="row mt-2">
            <div className="d-flex justify-content-end align-items-center px-2">
              <div className="col-3">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control  input-group-sm"
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* table */}
          <div className="p-2 table-container">
            <div className="card card_main ">
              <div
                className="card-body"
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  backgroundColor: "whitesmoke",
                }}
              >
                <div
                  className="table-responsive overflow-auto"
                  style={{ maxHeight: "500px" }}
                >
                  {filterData.length > 0 ? (
                    <>
                      <table className="table table-bordered">
                        <thead className="sticky-top bg-light">
                          <tr>
                            <th className="table_head text-dark" rowSpan={2}>
                              Ink ID
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Created Date
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Quotation Date
                            </th>
                            <th
                              className="table_head text-dark text-center"
                              colSpan={3}
                            >
                              INK ITEMS DATA
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              PO Number
                            </th>
                            <th className="table_headpo text-dark" rowSpan={2}>
                              PO Date
                            </th>
                            <th className="table_headpo text-dark" rowSpan={2}>
                              Total Qty
                            </th>
                            {/* <th className="table_head text-dark" rowSpan={2}>
                              Quantity Number
                            </th> */}
                            <th className="table_head text-dark" rowSpan={2}>
                              Unit
                            </th>
                            {/* <th className="table_head text-dark" rowSpan={2}>
                              Location
                            </th> */}
                            <th className="table_head text-dark" rowSpan={2}>
                              Tax Invoice Number
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Tax Invoice Quantity
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Tax Invoice Date
                            </th>

                            <th className="table_head text-dark" rowSpan={2}>
                              Rate
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Basic Rate
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Total GST(18%)
                            </th>
                            <th className="table_head text-dark" rowSpan={2}>
                              Total Amount
                            </th>
                          </tr>
                          <tr>
                            <th className="table_head text-dark">Ink Item</th>
                            <th className="table_head text-dark">
                              Description
                            </th>
                            {/* <th className="table_head text-dark">
                              Received Quantity
                            </th> */}
                            <th className="table_head text-dark">
                              Vendor location
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterData.map((item, index) => {
                            return (
                              <tr className="table_row" key={index}>
                                <td className="table_data">
                                  {item.inkconsumId}
                                </td>
                                <td>
                                  {new Date(item.created_date).toLocaleString()}
                                </td>
                                <td>{item.quantion_date}</td>
                                <td className="table_data">
                                  {item.ink_items?.ink_item || 0}
                                </td>
                                <td>{item.ink_items?.ink_descripation || 0}</td>
                                {/* <td>{item.ink_items?.consumablepersku || 0}</td> */}
                                <td>{item.ink_items?.location || 0}</td>
                                <td>{item.po_number}</td>
                                <td>{item.po_date}</td>
                                <td>{item.total_quantity}</td>
                                {/* <td>{item.quantity_number}</td> */}
                                <td>{item.unit}</td>
                                {/* <td>{item.location}</td> */}
                                <td>{item.taxinvoiceNo}</td>
                                <td>{item.taxinvoice_quantity}</td>
                                <td>{item.taxinvoice_date}</td>
                                <td>{item.rate}</td>
                                <td>{item.basic_rate}</td>
                                <td>{item.total_gst}</td>
                                <td>{item.total_ammount}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={6} className="text-right fw-bold">
                              Total:
                            </td>
                            <td className="fw-bold">
                              {/* {totals.consumablePerSKU} */}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="fw-bold">
                              {Number(totals.rate).toFixed(3)}
                            </td>
                            <td className="fw-bold">
                              {Number(totals.basicRate).toFixed(3)}
                            </td>
                            <td className="fw-bold">
                              {totals.totalGst.toFixed(3)}
                            </td>
                            <td className="fw-bold">
                              {totals.totalAmmount.toFixed(3)}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {totalPages > 0 ? (
                        <Pagination className="justify-content-center">
                          <Pagination.First
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                          />
                          <Pagination.Prev
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <Pagination.Last
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                          />
                        </Pagination>
                      ) : (
                        <div className="text-center text-danger">
                          <p>No Pages Found</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="d-flex justify-content-center ">
                      <p className="text-danger fw-bold">No Data Found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InkConsumption;
