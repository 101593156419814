import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MachineDetails from "./Pages/HeroSections/MachineDetails/MachineDetails";
import ProductionData from "./Pages/HeroSections/ProductionData/ProductionData";
import PlantDetails from "./Pages/HeroSections/PlantDetails/PlantDetails";
import SKUDetails from "./Pages/HeroSections/SKUDetails/SKUDetails";
import PlantSetup from "./Pages/HeroSections/PlantSetup/PlantSetup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Graphs from "./Pages/HeroSections/Graphs/Graphs";
import InkConsumption from "./Pages/HeroSections/InkConsumption/InkConsumption";
import ComponentPo from "./Pages/HeroSections/ComponentPo/ComponentPo";
import UserComp from "./Pages/Manager/UserComp";
import ManagerDetails from "./Pages/Manager/ManagerDetails";
import PageNotFound from "./Component/PageNotFound/PageNotFound";
import SuperAdmin from "./Pages/SuperUser/SuperAdmin";
// import DummyPo from "./Pages/HeroSections/DummyComponent/DummyPo";
import Ribbons from "./Pages/HeroSections/Ribbons/Ribbons";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="machinedetails" element={<MachineDetails />} />
          <Route path="addproduction" element={<ProductionData />} />
          <Route path="plantdetails" element={<PlantDetails />} />
          <Route path="skudetails" element={<SKUDetails />} />
          <Route path="plantsetup" element={<PlantSetup />} />
          <Route path="graphs" element={<Graphs />} />
          <Route path="ink" element={<InkConsumption />} />
          <Route path="ribbon" element={<Ribbons />} />
        </Route>
        <Route path="/user" element={<UserComp />}>
          <Route path="managerdetails" element={<ComponentPo />} />
        </Route>
        <Route path="/super_user" element={<SuperAdmin />}>
          <Route path="graphs" element={<Graphs />} />
          <Route path="ink" element={<InkConsumption />} />
          <Route path="supercomp" element={<ComponentPo />} />
          {/* <Route path="dummypo" element={<DummyPo />} /> */}
          <Route />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
