import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Mybutton from "../../../Component/Mybutton/Mybutton";
import CustomLoader from "../../../Component/Loader/CustomLoader";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
// import { toast, } from "react-toastify";
import { toast, Toaster } from "react-hot-toast";

import { BASE_URL } from "../../../Utils/constants";
import "./ProductionData.css";
// import { FaSpinner } from "react-icons/fa";
import { ImSpinner5 } from "react-icons/im";

function ProductionData() {
  // for notification
  // const notify = (notification, type) =>
  //   toast(notification, { autoClose: 1000, theme: "colored", type: type });

  const navigate = useNavigate();

  const [form, setForm] = useState({
    machine: {
      id: null,
      name: "Select Machine",
    },
    sku: {
      id: null,
      name: "Select SKU",
    },
    productionDate: "",
    productionKg: "",
    batchNo: "",
    working: true,
    remark: "Working",
    material_name: "",
  });

  const [state, setState] = useState([]);
  // console.log("state", state);

  const [productionList, setProductionList] = useState([]);
  console.log("productionList", productionList);

  const [skuData, setSkuData] = useState([]);
  console.log("skudata", skuData);

  const [machineData, setMachineData] = useState([]);

  const [filterDates, setFilterDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [plantData, setPlantData] = useState([]);
  // console.log("plant data", plantData);

  const [isDisable, setIsDisable] = useState(false);
  const [selectedPlantId, setSelectedPlantId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [search, setsearch] = useState("");
  const [loaderProd, setLoaderProd] = useState(true);

  const [bomdata, setBomData] = useState([]);
  const [selectedbomId, setSelectedBomId] = useState("");
  const [bomname, selectedBomName] = useState("");

  const handleForm = (e) => {
    const { name, value } = e.target;
    if ((name === "productionKg" || name === "batchNo") && value.length > 10) {
      return; // prevent updating state if value exceeds 10 digits
    }
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  // Toggle disable based on working status
  const handleDisable = (working) => {
    setIsDisable(!working);
    setForm((prev) => ({ ...prev, batchNo: "", productionKg: "" }));
  };

  // urls
  const getMachineDetails_url = BASE_URL + "api/getplantsetup/";
  const postProductionData_url = BASE_URL + "api/productiondata/";
  const getProductionData_url = BASE_URL + "api/productiondata/";
  const getPlantDetails_url = BASE_URL + "api/getplant/";
  const getBom_url = BASE_URL + "api/getbomdata"; //get method
  const skuWiseData_url = BASE_URL + "api/sku-productionkg/";
  const skuWiseData_urlLaminate = BASE_URL + "api/laminateProduction/";
  // "http://192.168.0.91:8080/api/laminateProduction/"
  //  "http://192.168.0.91:8080/api/sku-productionkg/";
  // const getInkConsumable_url =
  //   BASE_URL + "http://192.168.0.90:8080/api/getinkconsumble/";

  // "http://192.168.0.91:8080/api/laminateProduction/";

  // date selection upto 7 days not more than that

  const getbomData = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .get(getBom_url, { header: header })
      .then((res) => {
        if (res.data.status) {
          setBomData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getbomData();
  }, []);

  const currentDate = new Date();
  const maxDate = new Date(currentDate);
  maxDate.setDate(currentDate.getDate() + 7);
  const formattedMaxDate = maxDate.toISOString().split("T")[0];

  // get machine and sku Data
  const getData = async () => {
    setIsLoading(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getMachineDetails_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          setState(res.data?.data);

          const key = "machineID";

          // Step 1: Create arrayUniqueByKey with unique items based on machine[key]
          let arrayUniqueByKey = [
            ...new Map(
              res.data?.data.map((item) => [item.machine[key], item])
            ).values(),
          ];

          // Step 2: Filter arrayUniqueByKey by selectedPlantId
          arrayUniqueByKey = arrayUniqueByKey.filter((plantMachine) => {
            return plantMachine?.machine?.plant?.plantID === selectedPlantId;
          });

          // Step 3: Update state with filtered array
          setMachineData(arrayUniqueByKey);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        if (err.response?.status === 401) {
          // notify("Unathorized user", "error");
          toast("Unathorized user", "error");
          navigate("/");
        }
      });
  };

  // handle sku select against machine id
  const handleSelect = (machine) => {
    setForm((prev) => ({
      ...prev,
      machine: {
        id: machine?.machine?.machineID,
        name: machine?.machine?.machine_name,
      },
    }));
    let id = machine?.machine?.machineID;

    let filteredState = state.filter((item) => {
      return item?.machine?.machineID === id;
    });

    let skuArr = [];
    for (let i = 0; i < filteredState.length; i++) {
      let newObj = {
        name: filteredState[i]?.sku?.product_name,
        id: filteredState[i]?.sku?.skuID,
      };
      skuArr.push(newObj);
    }
    setSkuData(skuArr);
  };

  //handle BOM
  const handleBom = (bom) => {
    // setSelectedBomId(bom.bomID);
    setSelectedBomId(bom.bomID);
    selectedBomName(bom.material_name);
  };

  // get production data
  const getProductionData = async (startDate, endDate) => {
    setLoaderProd(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getProductionData_url, {
        headers: header,
        params: {
          start_date: startDate || "",
          end_date: endDate || "",
        },
      })
      .then((res) => {
        if (res.data.status) {
          setProductionList(res.data.data);
        }
        setLoaderProd(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          toast("Unathorized user", "error");
          navigate("/");
        }
        setLoaderProd(false);
      });
  };

  //plant data
  const getPlantDetails = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getPlantDetails_url, { headers: header })
      .then((res) => {
        // console.log("plant data", res.data.data);
        if (res.data.status) {
          setPlantData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          // notify("Unathorized user", "error");
          toast("Unathorized user", "error");
          navigate("/");
        }
      });
  };

  // post api
  const handleSubmit = async () => {
    if (
      !form.productionDate.length ||
      !form.batchNo.length ||
      !selectedbomId ||
      !form.machine.id ||
      !form.sku.id ||
      !form.remark.length
    ) {
      toast.error("All fields are required");
      return;
    }

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    let data = new FormData();
    data.append("production_Date", form.productionDate);
    data.append("production_Kg", form.productionKg);
    data.append("machine", form.machine.id);
    data.append("remark", form.remark);
    data.append("batchNo", form.batchNo);
    data.append("working", form.working === true ? "yes" : "no");
    data.append("skudata", JSON.stringify([form.sku.id]));
    data.append("tone", form.productionKg * 1000);
    data.append("laminatedata", JSON.stringify([selectedbomId]));

    await axios
      .post(postProductionData_url, data, { headers: header })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.messasge);
          setForm({
            machine: {
              id: null,
              name: "Select Machine",
            },
            sku: {
              id: null,
              name: "Select SKU",
            },
            productionDate: "",
            productionKg: "",
            batchNo: "",
            working: true,
            remark: "Working",
          });
          setSelectedPlantId("");
          setSelectedBomId("");
          selectedBomName("");
          setBomname("");
          setIsDisable(false);
          setSkuData([]);
          getProductionData();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
        if (err.response?.status === 401) {
          toast("Unathorized user", "error");
          navigate("/");
        }
      });
  };

  const downloadExcel = () => {
    function flattenSkillsArray(arr) {
      return arr.map((item) => {
        const flattenedItem = { ...item };

        if (item.skudata) {
          for (let i = 0; i < item.skudata.length; i++) {
            flattenedItem[`skuID`] = item.skudata[i].skuID;
            flattenedItem[`product_name`] = item.skudata[i].product_name;
            flattenedItem[`weight`] = item.skudata[i].weight;
          }
        }

        if (item.laminatedata) {
          for (let j = 0; j < item.laminatedata.length; j++) {
            flattenedItem[`bomID`] = item.laminatedata[j].bomID;
            flattenedItem[`material_name`] = item.laminatedata[j].material_name;
          }
        }
        // Add ribbon_used and ribbon_balance to flattened items
        flattenedItem[`ribbon_used`] = item.ribbon_used || 0;
        flattenedItem[`ribbon_balance`] = item.ribbon_balance || 0;

        delete flattenedItem.skudata;
        return flattenedItem;
      });
    }

    // Calculate the total production_Kg, totalRibbonUsed, and totalRibbonBalance
    const totalProductionKg = productionList.reduce(
      (total, item) => total + parseFloat(item.production_Kg || 0),
      0
    );
    const totalRibbonUsed = productionList.reduce(
      (total, item) => total + parseFloat(item.ribbon_used || 0),
      0
    );
    const totalRibbonBalance = productionList.reduce(
      (total, item) => total + parseFloat(item.ribbon_balance || 0),
      0
    );

    const flattenedData = flattenSkillsArray(productionList);

    // Add the total row to the flattened data
    const totalRow = {
      created_by: "Total",
      production_Kg: totalProductionKg.toFixed(2),
      ribbon_used: totalRibbonUsed.toFixed(2),
      ribbon_balance: totalRibbonBalance.toFixed(2),
    };

    flattenedData.push(totalRow);

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  //skuwise excel
  const downloadskuwiseExcel = async (startDate, endDate) => {
    try {
      let header = {
        Authorization: `Token ${localStorage.getItem("token")}`,
      };

      const laminateResponse = await axios.get(skuWiseData_urlLaminate, {
        headers: header,
        params: {
          start_date: startDate || "",
          end_date: endDate || "",
        },
      });
      // console.log(laminateResponse);

      // Aggregate data by unique SKUs
      const skuwiseDataMap = productionList.reduce((acc, item) => {
        item.skudata.forEach((sku) => {
          const skuName = sku.product_name;
          if (!acc[skuName]) {
            acc[skuName] = {
              Production_Date: item.production_Date,
              SkuName: skuName,
              ProductionPerKg: 0,
            };
          }

          acc[skuName].ProductionPerKg += item.production_Kg;
        });
        return acc;
      }, {});

      const skuwiseData = Object.values(skuwiseDataMap).map((item) => ({
        Production_Date: item.Production_Date,
        SkuName: item.SkuName,
        ProductionPerKg: item.ProductionPerKg,
      }));

      if (skuwiseData.length === 0) {
        console.warn(
          "No data found in skuwiseData. Check productionList and data mapping."
        );
        return; // Exit function if skuwiseData is empty
      }

      // Calculate the total production per kg
      const totalProductionPerKg = skuwiseData.reduce(
        (total, item) => (total += item.ProductionPerKg),
        0
      );

      const totalBomProductionKg = laminateResponse.data.laminate_data.reduce(
        (total, item) => (total += item.production_kg_sum),
        0
      );

      // Add the total production row to the data
      skuwiseData.push({
        Production_Date: "Total",
        SkuName: "",
        ProductionPerKg: totalProductionPerKg,
      });

      laminateResponse.data.laminate_data.push({
        bomID: "Total",
        material_name: "",
        production_kg_sum: totalBomProductionKg,
      });

      const worksheet = XLSX.utils.json_to_sheet(skuwiseData);
      const bomSheet = XLSX.utils.json_to_sheet(
        laminateResponse.data.laminate_data
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SKUWiseData");
      XLSX.utils.book_append_sheet(workbook, bomSheet, "BOM");

      try {
        XLSX.writeFile(workbook, "SKUWiseData.xlsx");
      } catch (error) {
        console.error("Error occurred while writing Excel file:", error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const downloadskuwiseExcel = async () => {
  //   try {
  //     let header = {
  //       Authorization: `Token ${localStorage.getItem("token")}`,
  //     };

  //     const response = await axios.get(skuWiseData_url, {
  //       headers: header,
  //     });

  //     const skuWiseData = response.data.data; // Accessing the 'data' array

  //     // Check if data is returned
  //     if (!skuWiseData || skuWiseData.length === 0) {
  //       console.warn("No SKU data available to download.");
  //       return;
  //     }

  //     // Map the data to the structure needed for Excel
  //     const excelData = skuWiseData.map((item) => ({
  //       SKU_ID: item.skuID,
  //       Product_Name: item.product_name,
  //       Production_KG_Sum: item.production_kg_sum,
  //     }));

  //     // Optionally, add a total row for Production_KG_Sum
  //     const totalProductionKg = excelData.reduce(
  //       (acc, item) => acc + item.Production_KG_Sum,
  //       0
  //     );
  //     excelData.push({
  //       SKU_ID: "Total",
  //       Product_Name: "",
  //       Production_KG_Sum: totalProductionKg,
  //     });

  //     // Create worksheet and workbook
  //     const worksheet = XLSX.utils.json_to_sheet(excelData);
  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "SKU Wise Production");

  //     // Trigger the download
  //     XLSX.writeFile(workbook, "SKUWiseProductionData.xlsx");

  //     console.log("Excel file downloaded successfully.");
  //   } catch (error) {
  //     console.error("Error fetching SKU-wise data:", error);
  //   }
  // };

  useEffect(() => {
    getPlantDetails();
    getProductionData();
  }, []);

  useEffect(() => {
    if (selectedPlantId) {
      getData();
    }
  }, [selectedPlantId]);

  let totalProductionKg = 0;
  // let totalTone = 0;
  let totalRibbonUsed = 0;
  let totalRibbonbalance = 0;

  productionList.forEach((item) => {
    totalProductionKg += item.production_Kg;
    // totalTone += item.tone;
    totalRibbonUsed += item.ribbon_used;
    totalRibbonbalance += item.ribbon_balance;
  });

  const filterData = productionList.filter(
    (itm) =>
      itm.productionId?.toLowerCase().includes(search.toLowerCase()) ||
      itm.created_by?.toLowerCase().includes(search.toLowerCase()) ||
      itm.createdtime?.toLowerCase().includes(search.toLowerCase()) ||
      itm.batchNo?.toLowerCase().includes(search.toLowerCase()) ||
      itm.production_Date?.toString().includes(search) ||
      itm.production_Kg?.toString().includes(search) ||
      itm.machine?.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
      itm.skudata.some(
        (sku) =>
          sku.skuID.includes(search) ||
          sku.product_name.toLowerCase().includes(search.toLowerCase()) ||
          sku.weight.toString().includes(search)
      ) ||
      itm.laminatedata.some(
        (laminate) =>
          laminate.bomID?.toLowerCase().includes(search.toLowerCase()) ||
          laminate.material_name.toLowerCase().includes(search.toLowerCase())
      )
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [bomnames, setBomname] = useState("");
  const filteredBomData = bomdata.filter(
    (bom) =>
      bom?.bomID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      bom?.material_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      // Move to the next item
      setSelectedIndex((prevIndex) =>
        Math.min(filteredBomData.length - 1, (prevIndex || 0) + 1)
      );
    } else if (e.key === "ArrowUp") {
      // Move to the previous item
      setSelectedIndex((prevIndex) => Math.max(0, (prevIndex || 0) - 1));
    } else if (e.key === "Enter" && selectedIndex !== null) {
      // Select the item
      handleBom(filteredBomData[selectedIndex]);
      setBomname(
        filteredBomData[selectedIndex].bomID +
          " (" +
          filteredBomData[selectedIndex].material_name +
          ")"
      );
    }
  };

  return (
    <>
      <Toaster />

      <div
        className="row p-2 m-3 main_class"
        style={{ border: "1px solid grey", backgroundColor: "whitesmoke" }}
      >
        {/* <Form.Check // prettier-ignore
          className="mx-4 "
          type="switch"
          id="custom-switch"
          label="Import Excel"
          checked={ }
        /> */}
        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <InputGroup>
            <DropdownButton variant="outline-secondary" title="Select Plant Id">
              {plantData.length
                ? plantData.map((plant) => {
                    return (
                      <Dropdown.Item
                        key={plant?.plantID}
                        onClick={() => setSelectedPlantId(plant?.plantID)}
                      >
                        {plant?.plantID}
                      </Dropdown.Item>
                    );
                  })
                : ""}
            </DropdownButton>
            <Form.Control
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              readOnly
              aria-label="Text input with dropdown button"
              value={selectedPlantId ? selectedPlantId : "Select plantId"}
            />
          </InputGroup>
        </div>
        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <InputGroup>
            <DropdownButton
              disabled={isLoading}
              variant="outline-secondary"
              title="Select Machine"
            >
              {machineData.length
                ? machineData.map((machine) => {
                    return (
                      <Dropdown.Item
                        key={machine?.machine?.machineID}
                        onClick={() => {
                          handleSelect(machine);
                          setForm((prev) => ({
                            ...prev,
                            sku: { name: "Select SKU", id: null },
                          }));
                        }}
                      >
                        {machine?.machine?.machine_name}
                      </Dropdown.Item>
                    );
                  })
                : ""}
            </DropdownButton>
            <Form.Control
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              readOnly
              aria-label="Text input with dropdown button"
              value={form.machine.name}
            />
          </InputGroup>
        </div>
        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <InputGroup>
            <DropdownButton
              disabled={isLoading}
              variant="outline-secondary"
              title="SKU Name"
            >
              {skuData.length ? (
                skuData.map((sku) => {
                  return (
                    <Dropdown.Item
                      key={sku?.id}
                      onClick={() => {
                        setForm((prev) => ({ ...prev, sku: sku }));
                      }}
                    >
                      {sku?.name}
                    </Dropdown.Item>
                  );
                })
              ) : (
                <Dropdown.Item
                  className="text-danger fw-medium"
                  style={{ fontSize: "13px" }}
                >
                  Select machine first
                </Dropdown.Item>
              )}
            </DropdownButton>
            <Form.Control
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              readOnly
              aria-label="Text input with dropdown button"
              value={form.sku.name}
            />
          </InputGroup>
        </div>

        {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <InputGroup>
            <DropdownButton variant="outline-secondary" title="Select BOM Id">
              {bomdata.length
                ? bomdata.map((bom) => {
                    return (
                      <Dropdown.Item
                        key={bom?.bomID}
                        onClick={() => handleBom(bom)}
                      >
                        {bom?.bomID + "(" + bom?.material_name + ")"}
                      </Dropdown.Item>
                    );
                  })
                : ""}
            </DropdownButton>
            <Form.Control
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              readOnly
              aria-label="Text input with dropdown button"
              value={bomname ? bomname : "Select bomID"}
            />
          </InputGroup>
        </div> */}

        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <InputGroup>
            <DropdownButton
              variant="outline-secondary"
              title="Select BOM Id"
              onKeyDown={handleKeyDown}
            >
              {/* Add search field inside dropdown */}
              <Dropdown.Header>
                <Form.Control
                  type="text"
                  placeholder="Search BOM ID or Material Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyDown}
                  style={{ marginBottom: "10px" }}
                />
              </Dropdown.Header>

              {/* Dropdown items filtered by search */}
              {filteredBomData.length ? (
                filteredBomData.map((bom) => (
                  <Dropdown.Item
                    key={bom?.bomID}
                    onClick={() => {
                      setBomname(bom?.bomID + " (" + bom?.material_name + ")");
                      handleBom(bom);
                    }}
                  >
                    {bom?.bomID + " (" + bom?.material_name + ")"}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item disabled>No BOM found</Dropdown.Item>
              )}
            </DropdownButton>
            <Form.Control
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              readOnly
              aria-label="Text input with dropdown button"
              value={bomnames || "Select BOM ID"}
            />
          </InputGroup>
        </div>

        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <div className="row">
            <div className="col-lg-6 px-1">
              <InputGroup
                style={{ border: "1px solid grey", borderRadius: "5px" }}
              >
                <InputGroup.Radio
                  // style={{ border: "1px solid grey ", borderRadius: "5px" }}
                  checked={form.working ? true : false}
                  onChange={() => {
                    setForm((prev) => ({
                      ...prev,
                      working: true,
                      remark: "Working",
                    }));
                    handleDisable(true);
                  }}
                  name="workingStatus"
                />
                <Form.Control
                  readOnly
                  placeholder="Working"
                  // style={{ border: "1px solid grey " }}

                  // style={{ border: "1px solid grey", borderRadius: "5px" }}
                />
              </InputGroup>
            </div>
            <div className="col-lg-6 px-1 mt-lg-0 mt-2">
              <InputGroup
                style={{ border: "1px solid grey", borderRadius: "5px" }}
              >
                <InputGroup.Radio
                  style={{ border: "1px solid grey " }}
                  checked={!form.working ? true : false}
                  onChange={() => {
                    setForm((prev) => ({
                      ...prev,
                      working: false,
                      remark: "",
                    }));
                    handleDisable(false);
                  }}
                  name="workingStatus"
                />
                <Form.Control readOnly placeholder="Not Working" />
              </InputGroup>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <div className="input-group">
            <span
              className="input-group-text"
              id="basic-addon1"
              style={{ border: "1px solid grey", borderRadius: "5px" }}
            >
              Production date
            </span>
            <input
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              type="date"
              value={form.productionDate}
              onChange={handleForm}
              name="productionDate"
              placeholder="YYYY-MM-DD"
              max={formattedMaxDate}
              className="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <div className="input-group">
            <span
              className="input-group-text"
              id="basic-addon1"
              style={{ border: "1px solid grey", borderRadius: "5px" }}
            >
              Production KG
            </span>
            <input
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              disabled={isDisable}
              value={form.productionKg}
              onChange={handleForm}
              name="productionKg"
              type="number"
              placeholder="in kg"
              className="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
              maxLength={10}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <div className="input-group">
            <span
              className="input-group-text"
              id="basic-addon1"
              style={{ border: "1px solid grey", borderRadius: "5px" }}
            >
              Batch No
            </span>
            <input
              style={{ border: "1px solid grey", borderRadius: "5px" }}
              value={form.batchNo}
              onChange={handleForm}
              name="batchNo"
              type="text"
              placeholder="Enter Batch No"
              className="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
              maxLength={10}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <div
            className="input-group"
            // style={{ border: "1px solid grey", borderRadius: "5px" }}
          >
            <span
              className="input-group-text"
              id="basic-addon1"
              style={{ border: "1px solid grey " }}
            >
              Remark
            </span>
            <input
              style={{ border: "1px solid grey " }}
              value={form.remark}
              onChange={handleForm}
              name="remark"
              type="text"
              placeholder="Enter Remark"
              className="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
          <div
            className="input-group"
          >
            <span
              className="input-group-text"
              id="basic-addon1"
              style={{ border: "1px solid grey " }}
            >
              Description
            </span>
            <input
              style={{ border: "1px solid grey " }}
              name="description"
              type="text"
              placeholder="Enter description"
              className="form-control"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
        </div> */}

        <div className="text-center p-2 mt-2 ">
          <Mybutton
            name={"Submit"}
            // backgroundColor={"#7b7bff"}
            color={"#fff"}
            handleClick={handleSubmit}
          />
        </div>
      </div>

      <div
        className="row p-2 m-3 mt-4 main_class"
        style={{ border: "1px solid grey", backgroundColor: "whitesmoke" }}
      >
        <div className="d-lg-flex align-items-center justify-content-center">
          <div className="col-lg-6 d-flex justify-content-lg-end">
            <h4 className="text-center px-4">Production Data List</h4>
          </div>
          <div className="col-lg-6 d-lg-flex me-4 justify-content-lg-end justify-content-center gap-2 align-items-center text-center ">
            <Mybutton
              name={"Sku-Wise Export "}
              color={"#fff"}
              // backgroundColor={"#7b7bff"}
              handleClick={() =>
                downloadskuwiseExcel(filterDates.startDate, filterDates.endDate)
              }
            />

            <Mybutton
              name={"Export All"}
              // color={"#fff"}
              // backgroundColor={"#7b7bff"}
              handleClick={downloadExcel}
            />
          </div>
        </div>
        <div className="my-3 mx-2 p-2">
          <li className="my-2 ms-2 text-dark">Filter By Date</li>
          <div className="d-lg-flex justify-content-start align-items-end gap-2 flex-wrap">
            <div>
              <h6 className="text-secondary mb-2" style={{ fontSize: "13px" }}>
                From Date
              </h6>
              <input
                style={{ border: "1px solid grey" }}
                onChange={(e) => {
                  setFilterDates((prev) => ({
                    ...prev,
                    startDate: e.target.value,
                  }));
                }}
                value={filterDates.startDate}
                type="date"
                placeholder="YYYY-MM-DD"
                className="form-control productiondate"
              />
            </div>
            <div>
              <h6
                className="text-secondary mb-2 my-2"
                style={{ fontSize: "13px" }}
              >
                To Date
              </h6>
              <input
                style={{ border: "1px solid grey" }}
                onChange={(e) => {
                  setFilterDates((prev) => ({
                    ...prev,
                    endDate: e.target.value,
                  }));
                }}
                value={filterDates.endDate}
                type="date"
                placeholder="YYYY-MM-DD"
                className="form-control productiondate"
              />
            </div>

            <div className="d-flex text-center gap-1 justify-content-center mt-2">
              <div>
                <Mybutton
                  name={"Search"}
                  style={{ backgroundColor: "#7b7bff" }}
                  handleClick={() => {
                    if (
                      filterDates.startDate.length &&
                      filterDates.endDate.length
                    ) {
                      getProductionData(
                        filterDates.startDate,
                        filterDates.endDate
                      );
                    }
                  }}
                />
              </div>

              <div>
                <Mybutton
                  backgroundColor={"#fb5e5e"}
                  name={"Reset Filter"}
                  handleClick={() => {
                    getProductionData();
                    setFilterDates({
                      startDate: "",
                      endDate: "",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="d-flex justify-content-end align-items-center px-2">
          <div className="col-3">
            <div class="input-group input-group-sm">
              <input
                className="form-control  input-group-sm"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {loaderProd ? (
        <div className="p-2 table-container">
          <div className="card card_main">
            <div className="card-body ">
              <center>
                {/* <i
                  className="fa fa-circle-o-notch fa-spin"
                  style={{ fontSize: 30 }}
                ></i>
                <p>Loading...</p> */}
                <ImSpinner5 icon="spinner" className="spinner" />
              </center>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="p-2 table-container">
            <div className="card card_main">
              <div
                className="card-body "
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  backgroundColor: "whitesmoke",
                }}
              >
                <div
                  className="table-responsive overflow-auto"
                  style={{ maxHeight: "500px" }}
                >
                  <table className="table table-bordered">
                    <thead className="sticky-top bg-light">
                      <tr>
                        <th className="table_head text-dark" rowSpan={2}>
                          Production Id
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Created By
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Created Time
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Batch No
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Production Date
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Production Kg
                        </th>
                        {/* <th className="table_head text-dark" rowSpan={2}>
                    Ton
                  </th> */}
                        <th className="table_head text-dark" rowSpan={2}>
                          Machine
                        </th>
                        {
                          <th className="table_head text-dark" rowSpan={2}>
                            BOM ID
                          </th>
                        }
                        {
                          <th className="table_head text-dark" rowSpan={2}>
                            Laminate Description
                          </th>
                        }
                        <th
                          className="table_head text-dark text-center"
                          colSpan={3}
                        >
                          SKU Data
                        </th>

                        <th className="table_head text-dark" rowSpan={2}>
                          Working
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Ribbon used(mtrs)
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Ribbon balance
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          No. of Packets
                        </th>
                        <th className="table_head text-dark" rowSpan={2}>
                          Remark
                        </th>
                      </tr>
                      <tr>
                        <th className="table_head text-dark">SKU Id</th>
                        <th className="table_head text-dark">Product Name</th>
                        <th className="table_head text-dark">Weight</th>
                      </tr>
                    </thead>
                    {filterData.length ? (
                      <tbody>
                        {filterData &&
                          filterData.map((item) => {
                            return (
                              <tr className="table_row" key={item.productionId}>
                                <td className="table_data">
                                  {item.productionId}
                                </td>
                                <td className="table_data">
                                  {item.created_by}
                                </td>
                                <td className="table_data">
                                  {item.createdtime}
                                </td>
                                <td className="table_data">{item.batchNo}</td>
                                <td className="table_data">
                                  {item.production_Date}
                                </td>
                                <td className="table_data">
                                  {item.production_Kg}
                                </td>
                                {/* <td className="table_data">{item.tone}</td> */}
                                <td className="table_data">{item.machine}</td>
                                {
                                  <td className="table_data">
                                    {item.laminatedata.length &&
                                      item.laminatedata.map((item) => {
                                        return (
                                          <div key={item.bomID}>
                                            {item.bomID}
                                          </div>
                                        );
                                      })}
                                  </td>
                                }
                                {
                                  <td className="table_data">
                                    {item.laminatedata.length &&
                                      item.laminatedata.map((item) => {
                                        return (
                                          <div key={item.bomID}>
                                            {item.material_name}
                                          </div>
                                        );
                                      })}
                                  </td>
                                }

                                <td className="table_data">
                                  {item.skudata.length &&
                                    item.skudata.map((skuItem) => {
                                      return (
                                        <div key={skuItem.skuID}>
                                          {skuItem.skuID}
                                        </div>
                                      );
                                    })}
                                </td>
                                <td className="table_data">
                                  {item.skudata.length &&
                                    item.skudata.map((skuItem) => {
                                      return (
                                        <div key={skuItem.product_name}>
                                          {skuItem.product_name}
                                        </div>
                                      );
                                    })}
                                </td>
                                <td className="table_data">
                                  {item.skudata.length &&
                                    item.skudata.map((skuItem) => {
                                      return (
                                        <div key={skuItem.weight}>
                                          {skuItem.weight}
                                        </div>
                                      );
                                    })}
                                </td>
                                <td className="table_data">
                                  {item.working ? "Yes" : "No"}
                                </td>
                                <td>{item.ribbon_used}</td>
                                <td>{item.ribbon_balance}</td>
                                <td>{item.no_packet}</td>
                                <td className="table_data">{item.remark}</td>
                              </tr>
                            );
                          })}

                        {/* start */}
                        <tr className="table_row">
                          <td colSpan={5} className="text-right fw-bold">
                            <strong>Total Production_Kg:</strong>
                          </td>
                          <td className="table_data fw-bold">
                            <strong>{totalProductionKg.toFixed(2)}</strong>
                          </td>

                          {/* <td className="table_data fw-bold">
                      <strong>{totalTone.toFixed(2)}</strong>
                    </td> */}
                          <td colSpan={7}></td>
                          <td className="table_data fw-bold">
                            <strong>{totalRibbonUsed.toFixed(2)}</strong>
                          </td>
                          <td className="table_data fw-bold">
                            <strong>{totalRibbonbalance.toFixed(2)}</strong>
                          </td>
                          <td colSpan={1}></td>
                        </tr>

                        {/* end */}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="table_data">
                          <td colSpan={13} className="text-danger text-center">
                            No data found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProductionData;
