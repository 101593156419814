import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../../Component/Loader/CustomLoader";
import { BASE_URL } from "../../../Utils/constants";

function MachineDetails() {
  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 2000, theme: "colored", type: type });

  const navigate = useNavigate();

  const [machineData, setMachineData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setsearch] = useState("");

  //url
  const getMachineDetails_url = BASE_URL + "api/getmachine/";

  const getMachineDetails = async () => {
    setLoader(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getMachineDetails_url, { headers: header })
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setMachineData(res?.data?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          notify("Unathorized user", "error");
          navigate("/");
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    getMachineDetails();
  }, []);

  const filterData = machineData.filter(
    (itm) =>
      itm.machineID.toLowerCase().includes(search.toLowerCase()) ||
      itm.machine_name.toLowerCase().includes(search.toLowerCase()) ||
      itm.plant.plantID.toString().includes(search) ||
      itm.plant.name.toLowerCase().includes(search.toLowerCase()) ||
      itm.plant.location.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <div className="row mt-2">
        <div className="d-flex justify-content-end align-items-center px-2">
          <div className="col-3">
            <div class="input-group input-group-sm">
              <input
                className="form-control  input-group-sm"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="p-2 table-container">
          <div className="card card_main">
            <div
              className="card-body"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              <div
                className="table-responsive overflow-auto"
                style={{ maxHeight: "500px" }}
              >
                <table className="table table-bordered">
                  <thead className="sticky-top bg-light">
                    <tr>
                      <th
                        className="table_head text-dark"
                        rowSpan={2}
                        style={{ width: "100px" }}
                      >
                        Machine ID
                      </th>
                      <th
                        className="table_head text-dark"
                        style={{ width: "120px" }}
                        rowSpan={2}
                      >
                        Machine Name
                      </th>
                      <th
                        className="table_head text-dark"
                        style={{ width: "60px" }}
                        rowSpan={2}
                      >
                        Printer Name
                      </th>
                      <th
                        className="table_head text-dark text-center"
                        // className="text-center"
                        colSpan={3}
                      >
                        Plant
                      </th>
                      {/* <th rowSpan={2}>SAP Code</th> */}
                    </tr>
                    <tr>
                      <th
                        className="table_head text-dark text-center"
                        style={{ width: "90px" }}
                      >
                        Plant ID
                      </th>
                      <th
                        className="table_head text-dark text-center"
                        style={{ width: "250px" }}
                      >
                        Name
                      </th>
                      <th
                        className="table_head text-dark text-center"
                        style={{ width: "100px" }}
                      >
                        Location
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData?.length ? (
                      filterData.map((item) => {
                        return (
                          <tr
                            className="table_row"
                            key={item.machineID + item.production_Date}
                          >
                            <td className="table_data">{item.machineID}</td>
                            <td className="table_data_machine">
                              {item.machine_name}
                            </td>
                            <td className="table_data">{item.printername}</td>
                            <td className="table_data">
                              {item?.plant?.plantID}
                            </td>
                            <td className="table_data">{item?.plant?.name}</td>
                            <td className="table_data">
                              {item?.plant?.location}
                            </td>
                            {/* <td>{item.sap_code}</td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-danger text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MachineDetails;
