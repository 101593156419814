import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SuperUserTabs.css";

function SuperUserTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const tabsArr = [
    {
      id: 1,
      name: "Ink Consumption",
      path: "/super_user/ink",
    },
    {
      id: 2,
      name: "Component Po",
      path: "/super_user/supercomp",
    },
    // {
    //   id: 3,
    //   name: "DummyPo",
    //   path: "/super_user/dummypo",
    // },
  ];

  return (
    <div
      className=" d-flex align-items-center 
      navTabContainer
      hidescrollbar
      main_tab px-2"
    >
      {tabsArr &&
        tabsArr.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => {
                navigate(item.path);
              }}
              className={`navTab p-2 m-1 ${
                location.pathname === item.path
                  ? "navTabActive"
                  : "navTabNotActive"
              }`}
            >
              {item.name}
            </div>
          );
        })}
    </div>
  );
}

export default SuperUserTabs;
