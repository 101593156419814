import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../../Component/Loader/CustomLoader";
import { BASE_URL } from "../../../Utils/constants";
import "./PlantSetup.css";
function PlantSetup() {
  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 2000, theme: "colored", type: type });

  const navigate = useNavigate();

  const [plantSetup, setPlantSetup] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setsearch] = useState("");

  //url
  const getPlantSetupDetails_url = BASE_URL + "api/getplantsetup/";

  const getPlantSetupDetails = async () => {
    setLoader(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getPlantSetupDetails_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          setPlantSetup(res?.data?.data);
          setLoader(false);
          // console.log("response", res);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          notify("Unathorized user", "error");
          navigate("/");
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    getPlantSetupDetails();
  }, []);

  const filterData = plantSetup.filter(
    (itm) =>
      itm.setupId?.toLowerCase().includes(search.toLowerCase()) ||
      itm.machine?.machineID?.toLowerCase().includes(search.toLowerCase()) ||
      itm.machine?.machine_name?.toLowerCase().includes(search.toLowerCase()) ||
      itm.machine?.plant?.plantID
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      itm.machine?.plant?.name?.toLowerCase().includes(search.toLowerCase()) ||
      itm.machine?.plant?.location
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      itm.sku?.skuID?.toLowerCase().includes(search.toLowerCase()) ||
      itm.sku?.product_name?.toLowerCase().includes(search.toLowerCase()) ||
      itm.sku?.weight?.toString().includes(search) ||
      itm.target?.toString().includes(search)
  );

  return (
    <>
      <div className="row mt-2">
        <div className="d-flex justify-content-end align-items-center px-2">
          <div className="col-3">
            <div class="input-group input-group-sm">
              <input
                className="form-control  input-group-sm"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="p-2 table-container">
          <div className="card card_main">
            <div
              className="card-body"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              <div
                className="table-responsive overflow-auto"
                style={{ maxHeight: "500px" }}
              >
                <table className="table table-bordered">
                  <thead className="sticky-top bg-light">
                    <tr>
                      <th className="table_head text-dark" rowSpan={3}>
                        Setup ID
                      </th>
                      <th
                        className="table_head text-dark text-center"
                        colSpan={6}
                      >
                        Machine
                      </th>
                      <th
                        className="table_head text-dark text-center"
                        colSpan={2}
                      >
                        SKU
                      </th>
                      <th className="table_head text-dark " rowSpan={3}>
                        Target
                      </th>
                    </tr>
                    <tr>
                      <th className="table_head text-dark " rowSpan={2}>
                        Machine ID
                      </th>
                      <th className="table_head text-dark " rowSpan={2}>
                        Machine Name
                      </th>

                      <th
                        className="table_head text-dark text-center"
                        colSpan={3}
                      >
                        Plant
                      </th>
                      <th className="table_head text-dark " rowSpan={2}>
                        SKU ID
                      </th>
                      <th className="table_head text-dark " rowSpan={2}>
                        Product Name
                      </th>
                      <th className="table_head text-dark " rowSpan={2}>
                        Weight
                      </th>
                    </tr>
                    <tr>
                      <th className="table_head text-dark ">Plant ID</th>
                      <th className="table_head text-dark ">Name</th>
                      <th className="table_head text-dark ">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData?.length ? (
                      filterData.map((item) => {
                        return (
                          <tr key={item.setupId} className="table_row">
                            <td>{item.setupId}</td>
                            <td>{item?.machine?.machineID}</td>
                            <td>{item?.machine?.machine_name}</td>
                            {/* <td>{item?.machine?.sap_code}</td> */}
                            <td>{item?.machine?.plant?.plantID}</td>
                            <td>{item?.machine?.plant?.name}</td>
                            <td>{item?.machine?.plant?.location}</td>
                            <td>{item?.sku?.skuID}</td>
                            <td>{item?.sku?.product_name}</td>
                            <td>{item?.sku?.weight}</td>
                            <td>{item.target}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-danger text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlantSetup;
