import React, { useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { BASE_URL, BASE_URL_MAIN } from "../../Utils/constants";
import user_icon from "../../Assets/svg/person.svg";
import lock from "../../Assets/svg/lock.svg";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

function Login() {
  const navigate = useNavigate();
  // abc

  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 800, theme: "colored", type: type });

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loader, setLoader] = useState(false);

  const [form, setForm] = useState({
    userName: "",
    password: "",
  });

  const handleForm = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const passwordRef = useRef(null);

  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      if (field === "username") {
        // Move focus to password field
        passwordRef.current.focus();
      } else if (field === "password") {
        // Trigger login if both fields are filled
        if (form.userName && form.password) {
          handleLogin();
        }
      }
    }
  };

  const login_url = BASE_URL + "login/";
  const loginAuth_url = BASE_URL_MAIN + "api/login?server=production/";
  const productionLogin_url = BASE_URL + "api/sapatauth/";
  // const loginManager_url = BASE_URL + "billmaterial/userlogin/";

  const handleLogin = async () => {
    setLoading1(true);
    let data = new FormData();
    data.append("username", form.userName);
    data.append("password", form.password);
    await axios
      .post(login_url, data)
      .then((res) => {
        setLoading1(false);
        if (res.data.status) {
          const userRole = res.data.username;
          // console.log(userRole);
          if (userRole === "productionmanager" || userRole === "manager") {
            // userRole === "manager";
            localStorage.setItem("token", res.data.token);
            navigate("/user/managerdetails");
            notify("Login successful", "success");
          } else if (userRole === "rushikesh123" || userRole === "rutik24") {
            // userRole === "rutik24";
            localStorage.setItem("token", res.data.token);
            // navigate("/super_user/graphs");
            navigate("/super_user/ink");
            notify("Login successful", "success");
          } else {
            localStorage.setItem("token", res?.data?.token);
            notify("Login successful", "success");
            navigate("/dashboard/plantdetails");
          }
        }
      })
      .catch((err) => {
        setLoading1(false);
        console.log(err);
        if (err?.response?.status === 400) {
          notify("Incorrect Credentails", "error");
        }
      });
  };

  const handleProductionLogin = async (token) => {
    const data = {
      access_token: token,
    };
    await axios
      .post(productionLogin_url, data)
      .then((res) => {
        if (res.data.status) {
          setLoading2(false);
          localStorage.setItem("token", res?.data?.token);
          notify("Login successful", "success");
          navigate("/dashboard/plantdetails");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleAuthLogin = async () => {
  //   setLoading2(true);
  //   if (!form.userName.length || !form.password.length) {
  //     setLoading2(false);
  //     return false;
  //   }
  //   let data = new FormData();
  //   data.append("empId", form.userName);
  //   data.append("password", form.password);
  //   await axios
  //     .post(loginAuth_url, data)
  //     .then((res) => {
  //       if (res.data.status) {
  //         handleProductionLogin(res.data.access_token);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading2(false);
  //       console.log(err);
  //       if (err.response.status === 400) {
  //         notify("Incorrect Credentails", "error");
  //       }
  //     });
  // };

  return (
    <>
      <section className="vh-100 gradient-custom">
        <div className="container py-5 h-100  ">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-12 col-md-8 col-lg-6 col-xl-5"
              style={{
                width: "350px",
              }}
            >
              <div
                className="card "
                style={{
                  borderradius: "1rem",
                  backgroundImage:
                    "linear-gradient(to bottom, #639cd9, violet)",

                  // background:
                  //   "radial-gradient(circle, rgb(250, 215, 140) 0%, rgb(135, 185, 185) 100%)",

                  color: "white",
                  boxshadow: "0px 4px 8px rgba(0, 0, 0, 0.7)",
                }}
              >
                <div className="card-body p-5 text-center">
                  <div>
                    <h2 className="log_in">LOG IN</h2>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Username"
                      className="mb-3 text-dark"
                      style={{
                        marginTop: "24px",
                      }}
                    >
                      <Form.Control
                        value={form.userName}
                        onChange={handleForm}
                        name="userName"
                        type="text"
                        placeholder="name@example.com"
                        onKeyDown={(e) => handleKeyDown(e, "username")}
                        style={{
                          backgroundColor: "rgb(229,231,235)",
                        }}
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      className="mb-4 text-dark"
                      controlId="floatingPassword"
                      label="Password"
                    >
                      <Form.Control
                        value={form.password}
                        onChange={handleForm}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        ref={passwordRef}
                        onKeyDown={(e) => handleKeyDown(e, "password")}
                        style={{
                          backgroundColor: "rgb(229,231,235)",
                        }}
                      />
                      <span
                        className="flex justify-around items-center pass_icon"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? (
                          <IoMdEye style={{ height: "20px", width: "20px" }} />
                        ) : (
                          <IoMdEyeOff
                            style={{ height: "20px", width: "20px" }}
                          />
                        )}
                      </span>
                    </FloatingLabel>

                    <div className="d-flex justify-content-between">
                      <Button
                        onClick={handleLogin}
                        className="mb-2"
                        variant="primary"
                        style={{
                          width: "100%",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.7)",
                          backgroundImage:
                            "linear-gradient(to bottom, #639cd9, violet)",
                        }}
                      >
                        {loading1 && <Spinner animation="border" size="sm" />}{" "}
                        Login
                      </Button>
                      {/* <Button
                        className="mb-2"
                        variant="primary"
                        onClick={handleManagerLogin}
                        style={{
                          width: "100%",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.7)",
                          backgroundImage:
                            "linear-gradient(to bottom, #639cd9, violet)",
                        }}
                      >
                        {loader && <Spinner animation="border" size="sm" />}{" "}
                        {""}
                        Manager
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
